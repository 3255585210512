<template>
  <div
    style="
    padding: 15px;"
  >
    <v-card class="px-3 py-2">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
        >
          <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
            SURAT JALAN
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refreshPage"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h6>
        </v-col>
        <v-col cols="12">
          <div>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <router-link to="/expedition/makeorder">
                <v-btn
                  outlined
                  elevation="1"
                  color="indigo"
                  class="indigo--text font-weight-bold"
                  style="font-size:12px;margin-top:5px;"
                  :disabled="loading"
                >
                  Buat Baru
                </v-btn>
              </router-link>
              <!-- <v-btn
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px; margin-left:10px;"
                :disabled="loading"
              >
                Sync odoo
              </v-btn> -->
              <v-btn
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size:12px;margin-top:5px; margin-left:10px;"
                :disabled="loading"
                @click="exportData"
              >
                Export
              </v-btn>
              <div
                style="width: 120px; display: flex; flex-direction: row; margin-left:10px;"
              >
                <v-select
                  :disabled="selectedData.length === 0"
                  label="Action"
                  style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                  v-model="actionValue"
                  :items="[
                    { id: 0, name: '' },
                    { id: 1, name: 'Track Vehicle' },
                    { id: 2, name: 'History' }
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  dense
                  @change="viewAction"
                ></v-select>
              </div>
              <div
                style="width: 180px; display: flex; flex-direction: row; margin-left: auto;"
              >
                <v-select
                  v-model="paramApi.company_id"
                  :items="dropdown.company"
                  @change="companyWatcher"
                  style="height:40px;"
                  label="Company"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-autocomplete
                  v-model="paramApi.customer_id"
                  :items="dropdown.customer"
                  :search-input.sync="dropdown.customerSearch"
                  @change="customerWatcher"
                  label="Customer"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-details
                  hide-no-data
                ></v-autocomplete>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-autocomplete
                  v-model="paramApi.sub_customer_id"
                  :items="dropdown.subCustomer"
                  :search-input.sync="dropdown.subCustomerSearch"
                  @change="subCustomerWatcher"
                  label="Sub Customer"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-no-data
                ></v-autocomplete>
              </div>
              <div
                style="width: 180px; display: flex; flex-direction: row; margin-left: 10px;"
              >
                <v-autocomplete
                  v-model="paramApi.unloading_location_id"
                  :items="dropdown.unloadingLocation"
                  :search-input.sync="dropdown.unloadingLocationSearch"
                  @change="unloadingLocationWatcher"
                  :disabled="paramApi.sub_customer_id === null"
                  label="Destinasi"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-no-data
                ></v-autocomplete>
              </div>
            </v-toolbar>
            <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
              <div style="width: 300px; display: flex; flex-direction: row;">
                <v-text-field
                  v-model="paramApi.keyword"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  label="cari transaksi"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  style="position: relative; height:40px;"
                ></v-text-field>
              </div>
              <div
                style="width: 180px; display: flex; flex-direction: row; margin-left: auto;"
              >
                <v-autocomplete
                  v-model="paramApi.loading_from_location_id"
                  :items="dropdown.loadingLocation"
                  :search-input.sync="dropdown.loadingLocationSearch"
                  @change="loadingLocationWatcher"
                  label="Loading"
                  style="margin: 0;height: 40px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  clearable
                  hide-no-data
                ></v-autocomplete>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-select
                  v-model="paramApi.zone_id"
                  :items="dropdown.zone"
                  @change="zoneWatcher"
                  style="height:40px;"
                  label="Zona"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-select
                  v-model="paramApi.status"
                  :items="dropdown.status"
                  @change="statusWatcher"
                  style="height:40px;"
                  label="Status"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  clearable
                  outlined
                ></v-select>
              </div>
              <div
                style="margin-left:10px; width: 180px; display: flex; flex-direction: row;"
              >
                <v-text-field
                  v-model="paramApi.order_date"
                  class="ml-1 mr-2"
                  dense
                  label="Tgl Order"
                  type="date"
                  name="orderdate"
                  style="height:40px;"
                  step="1"
                  outlined
                  clearable
                  @change="dateWatcher"
                />
              </div>
            </v-toolbar>
          </div>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            item-key="id"
            mobile-breakpoint="0"
            fixed-header
            height="60vh"
            :headers="headers"
            style="cursor: pointer;"
            :items="result"
            :loading="loading"
            @click:row="rowClick"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :page="paramApi.page"
            :server-items-length="totalData"
            :items-per-page="paramApi.itemsPerPage"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
            show-select
            :single-select="true"
          >
            <template v-slot:[`item.order_no`]="{ item }">
              <div
                style="font-size:12px; padding: 0; display: flex; flex-direction: row; font-weight:bold;"
              >
                {{ item.order_no }}
              </div>
            </template>
            <template v-slot:[`item.odoo_order_no`]="{ item }">
              <div
                style="font-size:11px; padding: 0; display: flex; flex-direction: row; font-weight:bold;"
              >
                {{ item.odoo_order_no }}
              </div>
            </template>
            <template v-slot:[`item.company_name`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.company_name }}
              </div>
            </template>
            <template v-slot:[`item.customer_name`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.customer_name }}
              </div>
            </template>
            <template v-slot:[`item.sub_customer_name`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row;"
              >
                {{ item.sub_customer_name }}
              </div>
            </template>
            <template v-slot:[`item.order_type`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row"
              >
                {{ item.order_type !== null ? item.order_type.name : '' }}
              </div>
            </template>
            <template v-slot:[`item.loading_location`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row"
              >
                {{
                  item.loading_location !== null
                    ? item.loading_location.name
                    : ''
                }}
              </div>
            </template>
            <template v-slot:[`item.unloading_location`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row"
              >
                {{
                  item.unloading_location !== null
                    ? item.unloading_location.name
                    : ''
                }}
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div>
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <td
                      v-bind="attrs"
                      v-on="on"
                      :style="
                        `border:0.5px solid rga(0,0,0,0.8); font-size:12px; border-radius:50%; height:22px; width:22px; color:white; font-weight:bold; background-color:${statusColor(
                          item.status.id
                        )};`
                      "
                      class="d-flex justify-center align-center"
                    >
                      {{ statusName(item.status.id) }}
                    </td>
                  </template>
                  <span style="font-size:12px;">{{ item.status.name }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.driver_name`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row"
              >
                {{ item.driver_name }}
              </div>
            </template>
            <template v-slot:[`item.license_plate`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row"
              >
                {{ item.license_plate }}
              </div>
            </template>
            <template v-slot:[`item.order_date`]="{ item }">
              <div
                style="font-size:10px; padding: 0; display: flex; flex-direction: row"
              >
                {{ item.order_date }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'ordertransaction',
  components: {},
  data: () => ({
    expedition: buildType.apiURL('expedition'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    loading: false,
    totalData: 0,
    result: [],
    paramApi: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      orderBy: 'order_date',
      orderType: 'desc',
      loading_from_location_id: null,
      unloading_location_id: null,
      zone_id: null,
      status: null,
      order_date: null,
      company_id: null,
      customer_id: null,
      sub_customer_id: null
    },
    options: {},

    headers: [
      {
        text: 'Order No',
        value: 'order_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'No SJ',
        value: 'odoo_order_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tipe',
        value: 'order_type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Customer',
        value: 'customer_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Sub Customer',
        value: 'sub_customer_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Loading',
        value: 'loading_location',
        align: 'left',
        sortable: false
      },
      {
        text: 'Destinasi',
        value: 'unloading_location',
        align: 'left',
        sortable: false
      },
      {
        text: 'Supir',
        value: 'driver_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nopol',
        value: 'license_plate',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tanggal',
        value: 'order_date',
        align: 'left',
        sortable: false
      }
    ],
    enableWatcher: true,
    selectedData: [],
    actionValue: 0,
    dropdown: {
      company: [],
      customer: [],
      customerSearch: null,
      customerSelect: null,

      subCustomer: [],
      subCustomerSearch: null,
      subCustomerSelect: null,

      loadingLocation: [],
      loadingLocationSearch: null,
      loadingLocationSelect: null,

      unloadingLocation: [],
      unloadingLocationSearch: null,
      unloadingLocationSelect: null,

      zone: [],
      status: []
    }
  }),
  async mounted() {
    this.getDataFromApi()
    this.initDropdown()
  },
  created() {},
  watch: {
    'dropdown.customerSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.customerSelect &&
          this.dropdownCustomer(val)
      } else {
        this.dropdown.customerSelect = null
        this.dropdown.customer = []
      }
    },
    'dropdown.subCustomerSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.subCustomerSelect &&
          this.dropdownSubCustomer(val)
      } else {
        this.dropdown.subCustomerSelect = null
        this.dropdown.subCustomer = []
      }
    },
    'dropdown.loadingLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.loadingLocationSelect &&
          this.dropdownLoadingLocation(val)
      } else {
        this.dropdown.loadingLocationSelect = null
        this.dropdown.loadingLocation = []
      }
    },
    'dropdown.unloadingLocationSearch'(val) {
      if (val !== '') {
        val &&
          val !== this.dropdown.unloadingLocationSelect &&
          this.dropdownUnloadingLocation(val)
      } else {
        this.dropdown.unloadingLocationSelect = null
        this.dropdown.unLoadingLocation = []
      }
    }
  },
  methods: {
    viewAction(event) {
      switch (event) {
        case 1:
          this.openTrackerWeb()
          break
        case 2:
          this.viewOrderHistory()
          break
      }
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    refreshPage() {
      this.getDataFromApi()
      this.initDropdown()
    },
    async getDataFromApi() {
      this.result = []
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      var url = `${this.expedition}transaction/list?&keyword=${
        // var url = `http://localhost:1196/api/esanqua/expedition/v1/transaction/list?&keyword=${
        this.paramApi.keyword
      }&offset=${this.paramApi.offset * this.paramApi.limit}&limit=${
        this.paramApi.limit
      }`
      // if (this.paramApi.company_id !== null) {
      const company_id = `&company_id=${
        this.paramApi.company_id !== null ? this.paramApi.company_id : ''
      }`
      const customer_id = `&customer_id=${
        this.paramApi.customer_id !== null ? this.paramApi.customer_id : ''
      }`
      // const sub_customer_id = `&sub_customer_id=${
      //   this.paramApi.sub_customer_id !== null
      //     ? this.paramApi.sub_customer_id
      //     : ''
      // }`
      const loading_from_location_id = `&loading_from_location_id=${
        this.paramApi.loading_from_location_id !== null
          ? this.paramApi.loading_from_location_id
          : ''
      }`
      const unloading_location_id = `&unloading_location_id=${
        this.paramApi.unloading_location_id !== null
          ? this.paramApi.unloading_location_id
          : ''
      }`
      const zone_id = `&zone_id=${
        this.paramApi.zone_id !== null ? this.paramApi.zone_id : ''
      }`
      const status = `&status=${
        this.paramApi.status !== null ? this.paramApi.status : ''
      }`
      const order_date = `&order_date=${
        this.paramApi.order_date !== null ? this.paramApi.order_date : ''
      }`
      const order_by = `&order_by=${this.paramApi.orderBy}`
      const order_type = `&order_type=${this.paramApi.orderType}`

      url =
        url +
        company_id +
        customer_id +
        // sub_customer_id +
        loading_from_location_id +
        unloading_location_id +
        zone_id +
        status +
        order_date +
        order_by +
        order_type

      return await new Promise(resolve => {
        axios
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    async initDropdown() {
      this.dropdownCompany()
      this.dropdownLoadingLocation('')
      this.dropdownZone()
      this.dropdownStatus()
    },

    async companyWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async zoneWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async statusWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async customerWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        this.dropdownSubCustomer('')
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async subCustomerWatcher(v) {
      // if (this.enableWatcher) {
      //   this.dropdownUnloadingLocation('')
      // }
    },
    async loadingLocationWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async unloadingLocationWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async dateWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async dropdownCompany() {
      this.dropdown.company = []
      await axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            const arr = res.data.data
            arr.unshift({ id: '', name: 'All' })
            return (this.dropdown.company = arr)
          }
          return (this.dropdown.company = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.company = [])
        })
    },
    async dropdownCustomer(v) {
      await axios
        .get(`${this.expedition}customer/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.customer = res.data.data)
          }
          return (this.dropdown.customer = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.customer = [])
        })
    },
    async dropdownSubCustomer(v) {
      await axios
        .get(
          `${this.expedition}sub_customer/dropdown?keyword=${v}&customer=${
            this.paramApi.customer_id !== null ? this.paramApi.customer_id : ''
          }`
        )
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.subCustomer = res.data.data)
          }
          return (this.dropdown.subCustomer = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.subCustomer = [])
        })
    },
    async dropdownLoadingLocation(v) {
      await axios
        .get(`${this.expedition}location/dropdown?keyword=${v}`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.loadingLocation = res.data.data)
          }
          return (this.dropdown.loadingLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.loadingLocation = [])
        })
    },
    async dropdownUnloadingLocation(v) {
      await axios
        .get(
          `${this.expedition}location/dropdown?keyword=${v}&sub_customer=${
            this.paramApi.sub_customer_id !== null
              ? this.paramApi.sub_customer_id
              : ''
          }`
        )
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.unloadingLocation = res.data.data)
          }
          return (this.dropdown.unloadingLocation = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.unloadingLocation = [])
        })
    },
    async dropdownZone() {
      await axios
        .get(`${this.expedition}zone/dropdown`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.zone = res.data.data)
          }
          return (this.dropdown.zone = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.zone = [])
        })
    },
    async dropdownStatus() {
      await axios
        .get(`${this.expedition}status/dropdown`)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.status = res.data.data)
          }
          return (this.dropdown.status = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.status = [])
        })
    },
    rowClick(pItem) {
      setTimeout(() => {
        this.$router.push(`/expedition/ordertransaction/detail/${pItem.id}`)
      }, 100)
    },

    async exportData() {
      this.loading = true
      const url = `${this.expedition}transaction/list?&keyword=&offset=0&limit=all&company_id=&customer_id=&loading_from_location_id=&unloading_location_id=&zone_id=&status=&order_date=&order_by=order_date&order_type=desc`

      await axios
        .get(url)
        .then(res => {
          console.log(res)
          let selectedData = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              selectedData.push({
                order_type: response[i].order_type,
                order_no: response[i].order_no,
                odoo_order_no: response[i].odoo_order_no,
                customer_name: response[i].customer_name,
                company_name: response[i].company_name,
                sub_customer_name: response[i].sub_customer_name,
                loading_from_company_name:
                  response[i].loading_from_company_name,
                loading_location: response[i].loading_location,
                unloading_location: response[i].unloading_location,
                driver_name: response[i].driver_name,
                license_plate: response[i].license_plate,
                status: response[i].status,
                order_date: response[i].order_date,
                other_loading: response[i].other_loading
              })
            }
            this.exportNow(selectedData)
          } else {
            selectedData = []
            this.loading = false
          }
          return null
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          return null
        })
    },
    exportNow(selectedData) {
      const arrData = []
      for (let i = 0; i < selectedData.length; i++) {
        const param = {
          order_type_id:
            selectedData[i].order_type !== null
              ? selectedData[i].order_type.id
              : '-',
          order_type_name:
            selectedData[i].order_type !== null
              ? selectedData[i].order_type.name
              : '-',
          order_no: selectedData[i].order_no,
          odoo_order_no: selectedData[i].odoo_order_no,
          customer_name: selectedData[i].customer_name,
          company_name: selectedData[i].company_name,
          sub_customer_name: selectedData[i].sub_customer_name,
          loading_from_company_name: selectedData[i].loading_from_company_name,
          loading_location_id:
            selectedData[i].loading_location !== null
              ? selectedData[i].loading_location.id
              : '-',
          loading_location_name:
            selectedData[i].loading_location !== null
              ? selectedData[i].loading_location.name
              : '-',
          unloading_location_id:
            selectedData[i].unloading_location !== null
              ? selectedData[i].unloading_location.id
              : '-',
          unloading_location_name:
            selectedData[i].unloading_location !== null
              ? selectedData[i].unloading_location.name
              : '-',
          other_loading_id:
            selectedData[i].other_loading !== null ? selectedData[i].id : '-',
          other_loading_name:
            selectedData[i].other_loading !== null ? selectedData[i].name : '-',
          driver_name: selectedData[i].driver_name,
          license_plate: selectedData[i].license_plate,
          status_id:
            selectedData[i].status !== null ? selectedData[i].status.id : '-',
          status_name:
            selectedData[i].status !== null ? selectedData[i].status.name : '-',
          order_date: selectedData[i].order_date
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      const date = new Date()
      XLSX.writeFile(wb, `SJ_REPORT_${date.toLocaleDateString('id')}.xlsx`)
      this.loading = false
    },
    searchEnter() {
      this.getDataFromApi()
    },
    async updatePage(p) {
      this.paramApi.page = p
      this.paramApi.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramApi.itemsPerPage = p
      this.paramApi.limit = p
      this.paramApi.offset = 0
      await this.getDataFromApi()
    },
    statusName(val) {
      switch (val) {
        case 0:
          return 'w'
        case 1:
          return 'gl'
        case 2:
          return 'al'
        case 3:
          return 'sl'
        case 4:
          return 'gl'
        case 5:
          return 'al'
        case 6:
          return 'sl'
        case 7:
          return 'dl'
        case 8:
          return 'ad'
        case 9:
          return 'ul'
        case 10:
          return 'f'
        case 11:
          return 'bp'
        default:
          break
      }
    },
    statusColor(val) {
      switch (val) {
        case 0:
          return 'orange'
        case 1:
          return 'blue'
        case 2:
          return 'blue'
        case 3:
          return 'blue'
        case 4:
          return 'blue'
        case 5:
          return 'blue'
        case 6:
          return 'blue'
        case 7:
          return 'blue'
        case 8:
          return 'blue'
        case 9:
          return 'blue'
        case 10:
          return 'green'
        case 11:
          return 'green'
        default:
          break
      }
    },
    openTrackerWeb() {
      this.showMsgDialog('info', 'COOMING SOON', false)
      setTimeout(() => {
        this.setAction()
      }, 200)
    },
    viewOrderHistory() {
      this.showMsgDialog('info', 'COOMING SOON', false)
      setTimeout(() => {
        this.setAction()
      }, 200)
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 576px) {
}
@media (max-width: 320px) {
}
</style>
